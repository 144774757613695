.chat {
	width: 100vw;
	height: 100vh;
	/* overflow: hidden; */
}

@supports (-webkit-touch-callout: none) {
	input,
	textarea {
		font-size: 16px; /* iOS Safari has issues with smaller font sizes */
	}
}

html,
body {
	height: 100%;
	overflow: hidden; /* Prevents scroll when keyboard appears */
}

body {
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}
