body {
	font-family: "Arial", sans-serif;
	/* background-color: #f0f8ff; */
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.bg-image {
	/* The image used */
	background-image: url("Background.jpg");

	/* Add the blur effect */
	filter: blur(8px);
	-webkit-filter: blur(8px);

	/* Full height */
	height: 100%;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.app {
	/* background: white; */
	/* border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	width: 100%;
	max-width: 600px;
	padding: 20px;
*/
	text-align: center;
}

.question-section,
.score-section {
	margin-bottom: 20px;
}

.question-text {
	margin: 20px 0;
	font-size: 1.2em;
}

.answer-section {
	display: flex;
	flex-direction: column;
	align-items: center;
}

button {
	background-color: #007bff; /* Blue */
	color: white;
	border: none;
	border-radius: 4px;
	padding: 10px 20px;
	margin: 10px;
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.3s;
}

button:hover {
	background-color: #0056b3; /* Darker Blue */
}

button:disabled {
	background-color: #cccccc;
	cursor: not-allowed;
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

input {
	margin: 10px 0;
	padding: 10px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	width: 80%;
	max-width: 300px;
	font-size: 1em;
}

input:focus {
	outline: none;
	border-color: #007bff;
}

h1 {
	color: #007bff;
}
