.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	position: absolute;
	padding-top: 30px;
}

.ripple {
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	/* background: #FFECBE; */
	background-color: transparent;
	animation: ripple 3000ms forwards;
	overflow: hidden;
}

@keyframes ripple {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 240, 232, 0.4),
			0 0 0 0 rgba(255, 240, 232, 0.4);
	}
	50% {
		box-shadow: 0 0 0 50px rgba(255, 240, 232, 0),
			0 0 0 0 rgba(255, 240, 232, 0.4);
	}
	100% {
		box-shadow: 0 0 0 100px rgba(255, 240, 232, 0),
			0 0 0 50px rgba(255, 240, 232, 0);
	}
}

.fade-container {
	animation: fadeIn 5000ms forwards;
	opacity: 0; /* Start from 30% opacity */
}

@keyframes fadeIn {
	to {
		opacity: 1;
	}
}
