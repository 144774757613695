@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Lora&display=swap");

@font-face {
	font-family: "Gotham";
	src: url("./fonts/Gotham-Medium.otf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Gotham2";
	src: url("./fonts/Gotham-Bold.otf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "GothamBoldItalic";
	src: url("./fonts/Gotham-BoldItalic.otf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: #ffecbe;
	transform: scale(1);
	transform-origin: 0 0;

	width: 100%;
	height: 100%;
	overflow: hidden;

	/* font-family: "Lora", serif; */
	font-family: "Bodoni Moda", serif;
	font-family: "Lora", serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1,
h2,
h3 {
	font-family: "Bodoni Moda", serif;
	font-weight: 300;
}

body > div {
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

*:focus {
	outline: none;
}

html,
body,
#root {
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: #fff;
	transform: scale(1);
	transform-origin: 0 0;
}
#root {
	display: flex;
}
